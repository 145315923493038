import React from "react";
import styled from "styled-components";
import CustomInput from "./CustomInput";

const FirstStep = ({ civilities, setCivility, civility }) => {
  return (
    <Container>
      <h2>Vos Informations</h2>
      <div className="inputs">
        <div className="select-wrp">
          <span className="label">
            Civilité<span className="red">*</span>
          </span>
          <select
            className="select width-unset"
            name="civility"
            value={civility}
            onChange={(e) => setCivility(e.target.value)}
          >
            {civilities.map((item) => (
              <option value={item.civility_id} key={item.civility_id}>
                {item.civility_name}
              </option>
            ))}
          </select>
        </div>
        <CustomInput
          label="Prénom"
          name="fname"
          id="fname"
          type="text"
          required
        />
        <CustomInput label="Nom" name="lname" id="lname" type="text" required />
        <CustomInput
          label="Adresse E-mail"
          name="email"
          id="email"
          type="text"
          required
        />
        <CustomInput
          label="Téléphone"
          name="phone"
          id="phone"
          type="text"
          required
        />
      </div>
    </Container>
  );
};

export default FirstStep;

const Container = styled.div`
  .label {
    margin-bottom: 0.35em;
    margin-top: 1.5em;
    font-size: 15px;
    font-weight: 600;
    color: #4d4d4d;
  }
  .select-wrp {
    display: flex;
    flex-direction: column;
  }
`;
