import styled from "styled-components";

const Popup = ({ setAction, message, setStep, color, error }) => {
  return (
    <Container color={color}>
      <div className="popup-container">
        {error ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 24 24"
            fill="#F84B60"
          >
            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 24 24"
            fill="#56E78E"
          >
            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z" />
          </svg>
        )}

        {!error ? <h2>Merci!</h2> : null}
        <p>{message}</p>
        <button
          className="popup-btn"
          onClick={() => {
            setAction(0);
            setStep(1);
          }}
        >
          Continuer
        </button>
      </div>
    </Container>
  );
};

export default Popup;

const Container = styled.div`
  .popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5000;
    max-width: 400px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 3em 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgb(237 239 247 / 67%) 0px 10px 20px,
      rgb(237 239 247 / 67%) 0px 6px 6px;
  }
  svg {
    margin-bottom: 2em;
  }
  h2 {
    font-size: 28px;
    color: #56e78e;
    margin: 0 0 0.5em 0 !important;
  }
  p {
    font-size: 14px;
    color: #444;
    font-weight: 500;
  }
  .popup-btn {
    padding: 12px 28px;
    border-radius: 4px;
    font-weight: 600;
    white-space: nowrap;
    margin-top: 2em;
    cursor: pointer;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    background: ${(props) => props.color};
    &:hover {
      filter: brightness(95%);
    }
  }
`;
