import React from "react";
import styled from "styled-components";

const CarDetailBlock = ({ values, color, img, getModel }) => {
  return (
    <Container color={color}>
      <div className="info-wrp-left">
        <div className="row">
          <h6>Matricule:</h6>
          <h6 className="mtr">
            {values.mtr1 + " " + values.mtr2 + " " + values.mtr3}
          </h6>
        </div>
        <div className="row">
          <h6>Num de série:</h6>
          <h6>{values.nbr}</h6>
        </div>
        <div className="row">
          <h6>Modèle:</h6>
          <h6>{getModel("name")}</h6>
        </div>

        {values.marque.length !== 0 ? (
          <div className="row">
            <h6>Marque:</h6>
            <h6>{values.marque}</h6>
          </div>
        ) : null}
      </div>
      <div className="info-wrp-right">
        <img src={getModel("img")} className="car-icon" alt="car" />
      </div>
    </Container>
  );
};
export default CarDetailBlock;

const Container = styled.div`
  width: 95%;
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 2em auto 0 auto;
  padding: 1em;
  border-radius: 12px;
  border: ${(props) =>
    props.color ? `2px solid ${props.color}` : `2px solid #000`};
  .row {
    display: grid;
    grid-template-columns: 50% 50%;
    h6 {
      font-weight: 400;
      font-size: 17px;
      color: #0a0c50;
      text-transform: unset !important;
      margin: 0.15em 0;
    }
  }
  .mtr {
    direction: rtl;
    text-align: left;
  }
  .info-wrp-right {
    display: flex;
    justify-content: center;
    align-items: center;
    .car-icon {
      width: 80%;
      height: 100%;
      margin-left: auto;
    }
  }
  .info-wrp-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: 100% !important;
    grid-template-rows: 1fr 1fr;
    .info-wrp-right {
      grid-row: 1/2;
    }
    .info-wrp-left {
      grid-row: 2/3;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .car-icon {
      height: 90% !important;
      margin: 0 auto;
    }
  }
`;
