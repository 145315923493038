import React from "react";
import styled from "styled-components";
import { useField } from "formik";
import Img from "../assets/carte-gris.jpeg";

const CustomInput = ({
  infos,
  margin,
  label,
  required,
  textarea,
  id,
  ...props
}) => {
  const [field, meta] = useField(props);

  const togglePassword = (id) => {
    const input = document.getElementById(id);
    if (input) {
      if (input.type === "password") {
        input.type = "text";
      } else {
        input.type = "password";
      }
    }
  };

  return (
    <Container margin={margin}>
      <div className="input-row">
        <label htmlFor={props.id || props.name}>
          {label} {required ? <span className="red">*</span> : null}
        </label>
        {infos ? (
          <div className="infos-popup">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18px"
              height="18px"
              fill="#c6c6c6"
              viewBox="0 0 24 24"
              className="infobulle"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"></path>
            </svg>
            <img src={Img} alt="infos" />
          </div>
        ) : null}
      </div>
      <div className="input-wrp">
        {!textarea ? (
          <input className="input" id={id} {...field} {...props} />
        ) : (
          <textarea className="input" {...field} {...props} rows="8" />
        )}

        {props.type === "password" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.875"
            height="14"
            viewBox="0 0 18.875 14"
            onClick={() => togglePassword(id)}
          >
            <g id="eye" transform="translate(-0.5 -3.5)">
              <path
                id="Path_17"
                data-name="Path 17"
                d="M1,10.5S4.25,4,9.938,4s8.937,6.5,8.937,6.5S15.625,17,9.938,17,1,10.5,1,10.5Z"
                fill="none"
                stroke="#7f7f7f"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
              <circle
                id="Ellipse_18"
                data-name="Ellipse 18"
                cx="3"
                cy="3"
                r="3"
                transform="translate(7 7.5)"
                strokeWidth="1"
                stroke="#7f7f7f"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              />
            </g>
          </svg>
        ) : null}
      </div>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </Container>
  );
};

export default CustomInput;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  label {
    font-size: 15px;
    font-weight: 600;
    color: #4d4d4d;
    margin-bottom: 0.35em;
    margin-top: 1.5em;
  }
  input,
  textarea {
    padding: 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    min-width: 320px;
    margin-bottom: 0.35em;
  }
  .error {
    font-size: 12px;
    color: red;
    font-weight: 600;
  }
  .input-wrp {
    position: relative;
  }
  svg {
    position: absolute;
    top: 14px;
    right: 10px;
    cursor: pointer;
  }
  .red {
    color: red;
  }
  .infos-popup {
    position: relative;
    margin-bottom: 0.35em;
    margin-top: 1.3em;
    img {
      position: absolute;
      right: 0.6em;
      bottom: 0.35em;
      width: 320px;
      z-index: 3;
      border-radius: 12px;
      display: none;
    }
    svg {
      position: absolute;
      top: -5px;
      right: 10px;
      cursor: pointer;
      :hover ~ img {
        display: block;
      }
    }
  }
  .input-row {
    height: min-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media only screen and (max-width: 768px) {
    margin: 0 !important;
  }
`;
