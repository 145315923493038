import styled from "styled-components";
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  .popup-container {
    max-width: 500px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
  }
  .popup-head {
    background: #e4ebf1;
    padding: 22px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  h4 {
    font-weight: 600;
    line-height: 1.25;
    font-size: 20px;
    color: #323a45;
  }
  p {
    color: #5a616a;
    font-size: 12px;
    font-weight: 500;
  }
  .popup-body {
    min-height: 121px;
    border-bottom: 1px solid #e4ebf1;
    padding: 20px;
  }
  .popup-footer {
    padding: 22px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .popup-btn {
    padding: 10px;
    border-radius: 4px;
    font-weight: 600;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    &:hover {
      filter: brightness(95%);
    }
  }
  .btn-1 {
    color: #5a616a;
    background: #e4ebf1;
  }
  .btn-2 {
    color: #fff;
    background: #f44235;
  }
  .btn-3 {
    color: #fff;
    background: #30c78a;
  }
`;

const Popup = ({ message, subject, dft, setAction }) => {
  return (
    <Container>
      <div className="popup-container">
        <div className="popup-head">
          <h4>{subject}</h4>
        </div>
        <div className="popup-body">
          <p>{message}</p>
        </div>
        <div className="popup-footer">
          <button
            className="popup-btn btn-1"
            style={!dft ? { display: "none" } : {}}
            onClick={() => setAction(false)}
          >
            {dft}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Popup;
