import React from "react";
import styled from "styled-components";

const Steps = ({ step, color }) => {
  return (
    <Container color={color}>
      <div className={step === 3 ? "nbr active-step" : "nbr"}>1</div>
      <hr />
      <div className={step === 4 ? "nbr active-step" : "nbr"}>2</div>
      <hr />
      <div className={step === 5 ? "nbr active-step" : "nbr"}>3</div>
      <hr />
      <div className={step === 6 ? "nbr active-step" : "nbr"}>4</div>
    </Container>
  );
};

export default Steps;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  margin-top: 1em;
  .nbr {
    width: 80px;
    height: 80px;
    font-size: 24px;
    font-weight: 600;
    color: ${(props) => props.color};
    border: 2px solid ${(props) => props.color};
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  hr {
    width: calc((100% - 320px) / 3);
    background: ${(props) => props.color};
    height: 2px;
    border: none;
  }
  .active-step {
    background: ${(props) => props.color};
    color: #fff;
  }
  @media only screen and (max-width: 576px) {
    .body {
      padding: 3em 1em;
    }
    .nbr {
      width: 40px;
      height: 40px;
      font-size: 18px;
    }
    hr {
      width: calc((100% - 160px) / 3);
      background: ${(props) => props.color};
      height: 2px;
      border: none;
    }
  }
`;
