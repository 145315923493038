import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Popup from "./components/Popup";
import Success from "./components/Success";
import Header from "./components/Header";
import Steps from "./components/Steps";
import NavigationButtons from "./components/NavigationButtons";
import FirstStep from "./components/FirstStep";
import SecondStep from "./components/SecondStep";
import ThirdStep from "./components/ThirdStep";
import FourthStep from "./components/FourthStep";
import FifthStep from "./components/FifthStep";
import Conditions from "./components/Conditions";
import SixthStep from "./components/SixthStep";

const App = () => {
  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
    name: "",
  });
  const [isLoading, setIsLoading] = useState(0);
  const [isLoading2, setIsLoading2] = useState(0);
  const [parentBrand, setParentBrand] = useState(false);
  const [isActive, setIsActive] = useState(0);
  const [theme, setTheme] = useState({});
  const [step, setStep] = useState(1);
  const [selectedTime, setSelectedTime] = useState(null);
  const [fulldate, setFullDate] = useState(new Date());
  const [date, setDate] = useState(null);
  const [formatedDate, setFormatedDate] = useState(null);
  const [action, setAction] = useState(false);
  const [senario, setSenario] = useState("Rendez vous SAV");
  const [intervention, setIntervention] = useState({
    id: "3",
    name: "Travaux Rapides",
  });
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [civility, setCivility] = useState("1");
  const [civilities, setCivilities] = useState([]);
  const [sucursale, setSucursale] = useState(null);
  const [sucursales, setSucursales] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState(null);
  const [models, setModels] = useState([]);
  const [model, setModel] = useState(null);
  const [vehicul, setVehicul] = useState("85");
  const [scenarios, setScenarios] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [hours, setHours] = useState([]);
  const [alphabets, setAlphabets] = useState([]);
  const [error, setError] = useState(0);
  const [brandName, setBrandName] = useState("FORD");
  const [brandAuto, setBrandAuto] = useState(2);
  const { REACT_APP_BACKEND_URL } = process.env;
  const { REACT_APP_API_URL } = process.env;

  const getSuccursales = async () => {
    setIsLoading(1);
    const interv = senario === "Rendez vous SAV" ? intervention.id : 0;
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/sucursales/${city}/${interv}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setSucursales(res);

      if (res.length) {
        setSucursale(res[0]);
        setCoordinates({
          ...coordinates,
          lat: parseFloat(res[0].lat),
          lng: parseFloat(res[0].lng),
          name: res[0].nom,
        });
        const d = new Date();
        const fd = d.toLocaleDateString("fr-CA");
        getHours(fd, res[0].id);
      } else {
        setSucursale(null);
      }
      setIsLoading(0);
    } catch (err) {
      console.log(err);
    }
  };

  const getCities = async () => {
    const interv = senario === "Rendez vous SAV" ? intervention.id : 0;
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/cities/${interv}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      let uniqueArray = [];
      uniqueArray = res.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
      setCities(uniqueArray);
      setCity(res[0].id);
    } catch (err) {
      console.log(err);
    }
  };

  const getHours = async (date, suc) => {
    const id = sucursale?.id || suc;
    if (id) {
      try {
        const response = await fetch(
          `${REACT_APP_BACKEND_URL}/api/hours/${date}/${intervention.id}/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const res = await response.json();
        const d = date ? new Date(date) : new Date();
        const today = new Date();
        const arr = [];

        res.map((item) => {
          if (parseInt(item.hora.split(":")[0]) > today.getHours()) {
            arr.push(item);
          }
        });

        if (
          !arr.length &&
          d.getDate() === today.getDate() &&
          d.getMonth() === today.getMonth()
        ) {
          setHours(arr);
        } else {
          setHours(res);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getBrands = async (name) => {
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/api/brands`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setBrands(res);
      // console.log("brands", res);
      if (name) {
        res.map((item) => {
          if (item.Nom.toLowerCase() === name.toLowerCase()) {
            setBrand(item.idMarque);
            setBrandAuto(item.idMarque);
          }
        });
      } else {
        setBrand(res[0].idMarque);
        setBrandAuto(res[0].idMarque);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getModels = async () => {
    setIsLoading2(1);
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/models/${brand}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setModels(res);
      setModel(res[0].idModele);
      setIsLoading2(0);
    } catch (err) {
      console.log(err);
    }
  };

  const getVehicule = async (mtr1, mtr2, mtr3) => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/vehicule/${mtr1}/${mtr2}/${mtr3}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setVehicul(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getCivilities = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/civilities", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setCivilities(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getrvAlphabets = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rvAlphabets", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setAlphabets(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getrvScenarios = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rvScenarios", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setScenarios(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getrvInterventions = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rvInterventions", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setInterventions(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getBrand = async (name) => {
    const { REACT_APP_BACKEND_URL } = process.env;
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/brandName/${name}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (result) {
        const obj = {
          id: result.brand_id,
          color: result.brand_color,
          logo: result.brand_img,
          name: result.brand_name,
        };

        setTheme(obj);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleDate = (date) => {
    const d = date.toLocaleDateString("fr-Fr", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setDate(d);
    setFullDate(date);
    const fd = date.toLocaleDateString("fr-CA");
    getHours(fd);
    setFormatedDate(fd);
  };

  useEffect(() => {
    getCivilities();
    getrvInterventions();
    getrvScenarios();
    getrvAlphabets();
    getCities();
    handleDate(new Date());

    if (typeof window !== undefined) {
      var url = new URL(window.location.href);
      var brand_name = url.searchParams.get("brand");
      if (brand_name !== null) {
        getBrand(brand_name);
        getBrands(brand_name);
      } else {
        getBrand("Autohall");
        setParentBrand(true);
        getBrands();
      }
    }
  }, []);

  useEffect(() => {
    getCities();
  }, [intervention]);

  useEffect(() => {
    if (city !== null) {
      getSuccursales();
    }
  }, [city]);

  useEffect(() => {
    if (senario !== null) {
      getCities();
    }
  }, [senario]);

  useEffect(() => {
    if (brand !== null) {
      getModels();
    }
  }, [brand]);

  const addRV = async (data) => {
    let alph = null;
    let time = null;
    alphabets.map((item) => {
      if (item.rvAlphabets_alphabet === data.mtr2) {
        alph = item.rvAlphabets_id;
      }
    });

    hours.map((item) => {
      if (item.hora === selectedTime) {
        time = item.id;
      }
    });

    const obj = {
      type: senario === "Rendez vous SAV" ? intervention.name : "",
      brand: brand,
      localBrand: theme.id,
      modele: model || "aucun",
      imm1: data.mtr1,
      imm2: data.mtr3,
      vin: data.nbr !== "" ? data.nbr : "1",
      name: data.fname + " " + data.lname,
      nom: data.lname,
      prenom: data.fname,
      email: data.email,
      phone: data.phone,
      typeinv: intervention.id,
      ville: city,
      succursale: sucursale.id,
      date: formatedDate,
      fulldate: formatedDate.replaceAll("-", "/") + "-" + selectedTime,
      hora: time,
      licenseplate: data.mtr1 + " " + data.mtr2 + " " + data.mtr3,
      lettre: alph,
      inv: senario,
      civ: civility,
      brandName: brandName,
      brand_auto: brandAuto,
    };
    console.log(obj);

    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rv", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });

      const res = await response.text();
      if (res) {
        console.log(res);
        if (res.includes("Exist already")) {
          setError(1);
        }
        setAction(true);
      }
    } catch (err) {
      console.log(err);
    }
    const objLead = {
      source: theme.name,
      tags: {
        ville: city,
        email: data.email,
        nom: data.lname,
        prenom: data.fname,
        marque: theme.id,
        origin: sucursale.id,
        plateforme: theme.name + "rdv",
        model: "none",
        telephone: data.phone,
        campgne: senario,

        succursale: sucursale.id,
        date: formatedDate,
        civ: civility,

        // marque: theme.id,
        // name: data.fname + " " + data.lname,
        // telephone: data.phone,
        // typeinv: intervention.id,
        // succursale: sucursale.id,
        // date: formatedDate,
        // fulldate: formatedDate.replaceAll("-", "/") + "-" + selectedTime,
        // hora: time,
        // inv: senario,
        // civ: civility,
        // brandName: brandName,
      },
    };
    if (senario === "Rendez vous Comercial") {
      try {
        const response = await fetch(
          REACT_APP_BACKEND_URL + "/leads/api-leads",
          {
            method: "POST",
            body: JSON.stringify(objLead),
          }
        );

        const res = await response.text();
        if (res) {
          console.log(res);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getModel = (type) => {
    let tempVar = null;
    models.map((item) => {
      if (item.idModele === model && type === "name") {
        tempVar = item.Nom;
      }
      if (item.idModele === model && type === "img") {
        tempVar = item.image;
      }
    });

    return tempVar;
  };

  const renderContent = (values, handleChange, handleBlur, errors) => {
    switch (step) {
      case 1:
        return (
          <FirstStep
            civilities={civilities}
            setCivility={setCivility}
            civility={civility}
          />
        );
      case 2:
        return (
          <SecondStep
            interventions={interventions}
            scenarios={scenarios}
            intervention={intervention}
            senario={senario}
            setSenario={setSenario}
            setIntervention={setIntervention}
            handleBlur={handleBlur}
            handleChange={handleChange}
            color={theme?.color}
          />
        );
      case 3:
        return (
          <ThirdStep
            values={values}
            alphabets={alphabets}
            handleBlur={handleBlur}
            handleChange={handleChange}
            errors={errors}
            color={theme?.color}
            parentBrand={parentBrand}
            brands={brands}
            setBrand={setBrand}
            brand={brand}
            model={model}
            models={models}
            setModel={setModel}
            getVehicule={getVehicule}
            isLoading={isLoading2}
            setBrandName={setBrandName}
            brandName={brandName}
            setBrandAuto={setBrandAuto}
          />
        );
      case 5:
        return (
          <FifthStep
            handleChange={handleChange}
            handleBlur={handleBlur}
            setSucursale={setSucursale}
            sucursale={sucursale}
            fulldate={fulldate}
            handleDate={handleDate}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            date={date}
            color={theme?.color}
            values={values}
            senario={senario}
            sucursales={sucursales}
            hours={hours}
            getModel={getModel}
          />
        );
      case 6:
        return (
          <SixthStep
            selectedTime={selectedTime}
            values={values}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            date={date}
            intervention={intervention}
            senario={senario}
            sucursale={sucursale}
            sucursales={sucursales}
            model={model}
            models={models}
            getModel={getModel}
            setIsActive={setIsActive}
          />
        );
      default:
        return;
    }
  };

  return (
    <Container color={theme?.color}>
      <Header logo={theme?.logo} color={theme?.color} />
      <h1>Prendre rendez-vous.</h1>
      <div className="body">
        {step > 2 ? <Steps step={step} color={theme?.color} /> : null}
        <Formik
          initialValues={{
            fname: "",
            lname: "",
            phone: "",
            email: "",
            mtr1: "",
            mtr2: "ا",
            mtr3: "",
            nbr: "",
            accepted: false,
            marque: "",
          }}
          validationSchema={Yup.object({
            fname: Yup.string()
              .matches(/^[a-zA-Z ]*$/, "Prénom Invalide")
              .required("Champ Obligatoire"),
            lname: Yup.string()
              .matches(/^[a-zA-Z ]*$/, "Nom Invalide")
              .required("Champ Obligatoire"),
            phone: Yup.string()
              .matches(
                /([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})/gm,
                "Numéro de téléphone est invalide"
              )
              .required("Champ Obligatoire"),
            email: Yup.string()
              .email("Invalid email address")
              .required("Champ Obligatoire"),
            nbr:
              step > 2 && senario === "Rendez vous SAV"
                ? Yup.string()
                    .min(17, "Doit contenir 17 caractères")
                    .max(17, "Doit contenir 17 caractères")
                    .required("Champ Obligatoire")
                : Yup.string(),
            mtr1:
              step > 2 && senario === "Rendez vous SAV"
                ? Yup.string()
                    .matches(/^[0-9]*$/, "Uniquement des chiffres")
                    .required("Champ Obligatoire")
                : Yup.string(),
            mtr3:
              step > 2 && senario === "Rendez vous SAV"
                ? Yup.string()
                    .matches(/^[0-9]*$/, "Uniquement des chiffres")
                    .required("Champ Obligatoire")
                : Yup.string(),
            accepted:
              step > 5
                ? Yup.string()
                    .required("Champ Obligatoire")
                    .matches(
                      true,
                      "vous devez accepter nos conditions d'utilisation"
                    )
                : Yup.string(),
          })}
          onSubmit={(data) => {
            if (step === 6) {
              addRV(data);
            } else {
              setStep(step + 1);
            }
          }}
        >
          {({ handleSubmit, values, handleChange, handleBlur, errors }) => (
            <Form className="form">
              {renderContent(values, handleChange, handleBlur, errors)}
              <div className={step === 4 ? "map-wrp" : "map-hide"}>
                <FourthStep
                  setCity={setCity}
                  setSucursale={setSucursale}
                  city={city}
                  sucursale={sucursale}
                  values={values}
                  color={theme?.color}
                  senario={senario}
                  sucursales={sucursales}
                  cities={cities}
                  getModel={getModel}
                  coordinates={coordinates}
                  setCoordinates={setCoordinates}
                  isLoading={isLoading}
                />
              </div>
              <NavigationButtons
                step={step}
                setStep={setStep}
                handleSubmit={handleSubmit}
                setAction={setAction}
                selectedTime={selectedTime}
                color={theme?.color}
                senario={senario}
                addRV={addRV}
              />
            </Form>
          )}
        </Formik>
      </div>
      {isActive ? <Conditions setIsActive={setIsActive} /> : null}
      {action && step === 5 ? (
        <Popup
          subject="Avertissement"
          message="Veuillez sélectionner une heure disponible"
          setAction={setAction}
          dft="Annuler"
        />
      ) : null}

      {action && step === 6 ? (
        <Success
          message={
            !error
              ? "Merci pour votre demande et à Très bientôt"
              : "Rendez-vous existe déjà!"
          }
          setAction={setAction}
          setStep={setStep}
          color={theme.color}
          error={error}
        />
      ) : null}
    </Container>
  );
};

export default App;

const Container = styled.div`
  background: #f7f8fc;
  min-height: 100vh;
  text-transform: unset !important;
  padding-bottom: 3em;
  position: relative;
  h1 {
    width: 100%;
    text-align: center;
    color: ${(props) => props.color};
    margin: 3em 0 1em 0;
    letter-spacing: 1px;
  }
  h2 {
    color: ${(props) => props.color};
    margin-top: 2em;
    margin-bottom: 1em;
    letter-spacing: 1px;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .body {
    background: #fff;
    border-radius: 12px;
    padding: 3em 4em;
    margin: auto;
    max-width: 1100px;
    box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
      rgb(237 239 247 / 47%) 0px 6px 6px;
  }
  .inputs {
    display: grid;
    grid-column-gap: 2.5em;
    grid-row-gap: 0.5em;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
  input {
    width: 100%;
  }
  .select {
    min-width: 320px;
    width: 100%;
    padding: 10px;
    max-width: 470px;
  }
  select {
    font-size: 14px;
    border-radius: 7px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    outline: none;
    color: #797575;
    cursor: pointer;
  }
  .width-unset {
    max-width: unset;
  }
  .error {
    font-size: 12px;
    color: red;
    font-weight: 600;
  }
  .type-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 630px;
    margin: 4em auto;
  }
  .img {
    cursor: pointer;
    width: 230px;
    height: 180px;
    border-radius: 20px;
  }
  .type-input {
    cursor: pointer;
    width: 244px;
    height: 194px;
    padding: 5px;
    border-radius: 20px;
    position: relative;
    background: transparent;
    border: 2px solid transparent;
    &:hover {
      border: ${(props) =>
        props.color ? `2px solid ${props.color}` : "2px solid #557bfc"};
    }
    span {
      cursor: pointer;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      text-align: center;
    }
  }
  .red {
    color: red;
  }
  label {
    font-size: 15px;
    font-weight: 600;
    color: #4d4d4d;
    margin-bottom: 0.35em;
    margin-top: 1.5em;
  }
  .active-type {
    border: ${(props) =>
      props.color ? `2px solid ${props.color}` : "2px solid #557bfc"};
  }
  .city-select select {
    width: unset !important;
  }
  .select-wrp {
    display: flex;
    flex-direction: column;
  }
  h5 {
    font-weight: 700;
  }
  h3 {
    font-weight: 400;
    font-size: 24px;
    color: ${(props) => props.color};
    margin-top: 1em;
    width: 100%;
    text-align: center;
    letter-spacing: 0.5px;
    margin-bottom: -2.5em;
    span {
      color: #787885;
    }
  }
  .map-hide {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .body {
      padding: 3em 1em;
    }
  }
  @media only screen and (max-width: 576px) {
    .type-inputs {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
    .type-input {
      width: 100%;
      max-width: 480px;
      margin-bottom: 1em;
      height: 224px;
      img {
        width: 100%;
        height: 210px;
        object-fit: cover;
      }
    }
    h2 {
      font-size: 20px;
    }
    .body {
      padding: 3em 0.75em;
    }
    .inputs {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
    h6 {
      font-size: 14px !important;
    }
    h3 {
      font-size: 18px;
    }
    .back-btn {
      margin-left: 0px;
    }
    .btn {
      padding: 8px 22px;
    }
  }
`;
