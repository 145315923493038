import React from "react";
import { Calendar } from "react-date-range";
import styled from "styled-components";
import fr from "date-fns/locale/fr";
import HourButton from "./HourButton";
import CarDetailBlock from "./CarDetailBlock";
import Car from "../assets/ford.png";

const FifthStep = ({
  setSucursale,
  sucursale,
  fulldate,
  handleDate,
  selectedTime,
  setSelectedTime,
  date,
  values,
  senario,
  color,
  sucursales,
  hours,
  getModel,
}) => {
  const today = new Date();

  return (
    <Container color={color}>
      <div className="date">
        {senario !== "Rendez vous Commercial" &&
        senario !== "Rendez vous Comercial" ? (
          <CarDetailBlock
            values={values}
            img={Car}
            color={color}
            getModel={getModel}
          />
        ) : null}
        <h2 className="h2-title">Choisissez la date et l'heure disponibles</h2>
        <select
          className="select width-unset"
          name="succursale"
          value={JSON.stringify(sucursale)}
          onChange={(e) => {
            const v = JSON.parse(e.target.value);
            setSucursale(v);
          }}
        >
          {sucursales.map((item, index) => (
            <option value={JSON.stringify(item)} key={index}>
              {item.nom}
            </option>
          ))}
        </select>
      </div>
      <div className="datepicker-container">
        <div className="calandar-container">
          <Calendar
            locale={fr}
            date={fulldate}
            onChange={handleDate}
            minDate={today}
            disabledDay={(date) => {
              return date.getDay() === 0 || date.getDay() === 6;
            }}
          />
        </div>
        <div className="hours-container">
          {hours.map((time) => (
            <HourButton
              time={time.hora}
              id={time.id}
              status="free"
              setSelectedTime={setSelectedTime}
              selectedTime={selectedTime}
              key={time.id}
              color={color}
            />
          ))}
        </div>
      </div>
      {selectedTime ? (
        <h3>
          <span>Date choisie: </span>
          {date + " à " + selectedTime}
        </h3>
      ) : null}
    </Container>
  );
};

export default FifthStep;

const Container = styled.div`
  .datepicker-container {
    display: grid;
    grid-template-columns: 340px auto;
  }
  .calandar-container {
    max-width: 340px;
  }
  .hours-container {
    margin: 20px 0 20px 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-template-rows: 60px 60px;
  }
  .h2-title {
    margin-top: 2em !important;
    margin-bottom: 1em !important;
  }

  @media only screen and (max-width: 992px) {
    .datepicker-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .hours-container {
      width: 100%;
      max-width: 500px;
      margin: 20px 0 20px 0px;
    }
    .date {
      align-items: flex-start;
      flex-direction: column;
      select {
        max-width: unset !important;
        width: 100%;
        margin-top: 2em;
      }
    }
  }
`;
