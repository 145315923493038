import React from "react";
import styled from "styled-components";

const HourButton = ({ time, status, selectedTime, setSelectedTime, color }) => {
  return (
    <Container stauts={status} color={color}>
      <span
        onClick={() => setSelectedTime(time)}
        className={selectedTime === time ? "selected" : ""}
      >
        {time}
      </span>
    </Container>
  );
};

export default HourButton;

const Container = styled.div`
  span {
    height: 40px;
    background-color: ${(props) =>
      props.stauts === "free" ? "#fff" : "#DFDFE8"};
    border-radius: 20px;
    border: 1px solid #dfdfe8;
    padding: 5px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7a7585;
    font-size: 1em;
    cursor: pointer;
    width: 90%;
  }
  .selected {
    background: ${(props) => (props.color ? props.color : "#2d4185")};
    color: #fff;
  }
`;
