import React from "react";
import styled from "styled-components";
import Map from "./Map";
import CarDetailBlock from "./CarDetailBlock";
import Car from "../assets/ford.png";

const FourthStep = ({
  setCity,
  setSucursale,
  city,
  sucursale,
  values,
  senario,
  color,
  cities,
  sucursales,
  getModel,
  coordinates,
  setCoordinates,
  isLoading,
}) => {
  return (
    <Container color={color}>
      {senario !== "Rendez vous Commercial" &&
      senario !== "Rendez vous Comercial" ? (
        <CarDetailBlock
          values={values}
          img={Car}
          color={color}
          getModel={getModel}
        />
      ) : null}
      <h2>Choisir votre succursale</h2>
      <div className="inputs">
        <div className="select-wrp">
          <select
            className="select width-unset"
            name="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          >
            {cities.map((item, index) => (
              <option value={item.id} key={index}>
                {item.nom}
              </option>
            ))}
          </select>
        </div>
        <div className="input-label">
          <select
            className="select width-unset"
            name="succursale"
            value={JSON.stringify(sucursale)}
            onChange={(e) => {
              const v = JSON.parse(e.target.value);
              setSucursale(v);
              setCoordinates({
                ...coordinates,
                lat: parseFloat(v.lat),
                lng: parseFloat(v.lng),
                name: v.nom,
              });
            }}
          >
            {!isLoading ? (
              sucursales.map((item, index) => (
                <option value={JSON.stringify(item)} key={index}>
                  {item.nom}
                </option>
              ))
            ) : (
              <option value="0">Chargement en cours...</option>
            )}
          </select>
        </div>
      </div>
      <div className="map-wrp">
        <Map coordinates={coordinates} parent="true" />
      </div>
    </Container>
  );
};

export default FourthStep;

const Container = styled.div`
  iframe {
    margin-top: 2.5em;
    margin-bottom: -2.5em;
  }
  .map-wrp {
    margin: 0.75em 0 0;
    overflow: hidden;
    border-radius: 12px;
  }
`;
