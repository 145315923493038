import React, { useEffect, useState } from "react";
import { compose, withProps } from "recompose";
import styled from "styled-components";
import Geocode from "react-geocode";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  InfoWindow,
} from "react-google-maps";
import MarckerIcone from "../assets/location-pin.png";

const MapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAv-wSoyStGEyGxZGvIou7MX9ufksIvmo8&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `0` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ location, search, coordinates }) => {
  const styles = [
    {
      featureType: "administrative.country",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#212121",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#212121",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#181818",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1b1b1b",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#2c2c2c",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#8a8a8a",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#373737",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#3c3c3c",
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry",
      stylers: [
        {
          color: "#4e4e4e",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#3d3d3d",
        },
      ],
    },
  ];
  const layer = [
    {
      query: {
        select: "geometry",
        from: "1S4aLkBE5u_WS0WMVSchhBgMLdAARuPEjyW4rs20",
        where: "col1 contains 'MAR'",
        styles: [
          {
            polylineOptions: {
              strokeColor: "#333333",
              strokeWeight: 2,
            },
          },
        ],
        suppressInfoWindows: true,
      },
    },
  ];
  const mapStyles = {
    height: "100%",
    width: "100%",
    borderRadius: "7px",
  };
  const [isClicked, setIsClicked] = useState(-1);

  const [markers, setMarkers] = useState([]);

  const getAddress = async () => {
    Geocode.setApiKey("AIzaSyAv-wSoyStGEyGxZGvIou7MX9ufksIvmo8");
    Geocode.setLanguage("en");
    const array = [];

    await Geocode.fromAddress(search).then(
      async (response) => {
        response.results.map(async (result) => {
          const { lat, lng } = await result.geometry.location;
          array.push({
            lat: lat,
            lng: lng,
            address: result.formatted_address,
          });
        });
      },
      (error) => {
        console.error(error);
      }
    );
    setMarkers(array);
  };

  useEffect(() => {
    getAddress();
  }, [search]);

  useEffect(() => {
    setMarkers([...new Array(coordinates)]);
  }, [coordinates]);

  return (
    <GoogleMap
      defaultZoom={11}
      center={markers[0] || location}
      options={{
        styles: styles,
        layer: layer,
        disableDefaultUI: true,
        fullscreenControl: true,
      }}
      styles={mapStyles}
    >
      {markers.map((item, index) => (
        <Marker
          position={{ lat: item.lat, lng: item.lng }}
          key={index}
          icon={MarckerIcone}
          onClick={() => setIsClicked(index)}
        >
          {isClicked === index ? (
            <InfoWindow onCloseClick={() => setIsClicked(-1)}>
              <span>{item.name}</span>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  );
});

const Map = ({ parent, height, minhieght, margin, search, coordinates }) => {
  const [isMarkerShown, setIsMarkerShown] = useState(false);
  const [location, setLocation] = useState({ lat: 30.4278, lng: -9.5981 });
  const delayedShowMarker = () => {
    setTimeout(() => {
      setIsMarkerShown(true);
    }, 2000);
  };
  const handleMarkerClick = () => {
    setIsMarkerShown(false);
    delayedShowMarker();
  };

  return (
    <Container
      parent={parent}
      height={height}
      minhieght={minhieght}
      margin={margin}
      className="testing"
    >
      <MapComponent
        isMarkerShown={isMarkerShown}
        onMarkerClick={handleMarkerClick}
        location={location}
        search={search}
        coordinates={coordinates}
        parent={parent}
        height={height}
        minhieght={minhieght}
        margin={margin}
      />
    </Container>
  );
};

export default Map;
const Container = styled.div`
  width: ${(props) =>
    props.parent ? "100%" : "calc((100vw - 240px - 2.5em) / 2)"};
  min-height: ${(props) => (props.minhieght ? "250px" : "unset")};
  height: ${(props) => (props.height ? props.height : "500px")};
  border-radius: 7px;
  position: relative;
  margin: ${(props) => (props.margin ? props.margin : " 1em 0.5em 0.75em 0")};

  //testing original value was 999px
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-height: unset;
    height: 300px;
  }
`;
