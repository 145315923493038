import React, { useState } from "react";
import styled from "styled-components";
import Mrc from "../assets/mrc.png";
import CustomInput from "./CustomInput";
import CarDetailBlock from "./CarDetailBlock";
import Car from "../assets/ford.png";
const { REACT_APP_BACKEND_URL } = process.env;

const ThirdStep = ({
  handleChange,
  handleBlur,
  values,
  errors,
  color,
  parentBrand,
  alphabets,
  brands,
  models,
  brand,
  brandName,
  setBrandName,
  model,
  setBrand,
  setModel,
  getVehicule,
  isLoading,
  setBrandAuto,
}) => {
  const [mtr, setMtr] = useState({
    mtr1: null,
    mtr2: "ا",
    mtr3: null,
  });
  const [showCarInfo, setShowCarInfo] = useState(0);
  const [showInputs, setShowInputs] = useState(1);

  const checkMtr = async () => {
    let alph = null;

    alphabets.map((item) => {
      if (item.rvAlphabets_alphabet === mtr.mtr2) {
        alph = item.rvAlphabets_id;
      }
    });

    const res = await getVehicule(mtr.mtr1, alph, mtr.mtr3);
    if (res) {
      setShowInputs(0);
      setShowCarInfo(1);
    } else {
      setShowInputs(1);
      setShowCarInfo(0);
    }
  };

  const GetIdBrandAuto = async (brand, brands) => {
    console.log("brands", brands);
    brands.map((item) => {
      if (item.idMarque === brand) {
        setBrandName(item.Nom);
      }
    });
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/brands`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      console.log("res", res);

      res.map((item) => {
        if (item.brand_name.toLowerCase() === brandName.toLowerCase()) {
          // console.log("item.brand_id", item.brand_id);
          setBrandAuto(item.brand_id);
          // console.log("brandname", brandName);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Container color={color}>
      <h2>Votre Immatriculation et modèle </h2>
      <div className="inputs">
        <div className="mtr-label-wrp">
          <label>
            Matricule <span className="red">*</span>
          </label>
          <div className="mtr-input-wrp">
            <input
              className="mtr-input mtr-1"
              type="text"
              placeholder="122372"
              name="mtr1"
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                setMtr({ ...mtr, mtr1: values.mtr1 });
                if (e.target.value && values.mtr3 !== "") {
                  checkMtr();
                }
              }}
              value={values.mtr1}
            />
            <select
              className="mtr-input mtr-2"
              name="mtr2"
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                setMtr({ ...mtr, mtr2: values.mtr2 });
                if (
                  e.target.value &&
                  values.mtr1 !== "" &&
                  values.mtr3 !== ""
                ) {
                  checkMtr();
                }
              }}
              value={values.mtr2}
            >
              {alphabets.map((alph, index) => (
                <option value={alph.rvAlphabets_alphabet} key={index}>
                  {alph.rvAlphabets_alphabet}
                </option>
              ))}
            </select>

            <input
              className="mtr-input mtr-3"
              type="text"
              placeholder="33"
              name="mtr3"
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                setMtr({ ...mtr, mtr3: values.mtr3 });
                if (e.target.value && values.mtr1 !== "") {
                  checkMtr();
                }
              }}
              value={values.mtr3}
            />
            <img src={Mrc} alt="maroc" className="country" />
          </div>
          {errors.mtr1 || errors.mtr2 || errors.mtr3 ? (
            <div className="error">
              {errors.mtr1 || errors.mtr2 || errors.mtr3}
            </div>
          ) : null}
        </div>
        {showInputs ? (
          <>
            <CustomInput
              label="Numéro de série"
              name="nbr"
              id="nbr"
              type="text"
              placeholder=""
              infos
              required
            />
            {parentBrand ? (
              <div className="select-wrp ">
                <label>Marque</label>
                <select
                  className="select"
                  name="marque"
                  value={brand}
                  onChange={(e) => {
                    setBrand(e.target.value);
                    GetIdBrandAuto(e.target.value, brands);
                  }}
                  onBlur={(e) => {
                    setBrand(e.target.value);
                    GetIdBrandAuto(e.target.value, brands);
                  }}
                >
                  {brands.map((item, index) => (
                    <option value={item.idMarque} key={index}>
                      {item.Nom}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
            <div className="select-wrp ">
              <label>Modèle</label>
              <select
                className="select"
                value={model}
                name="model"
                onChange={(e) => setModel(e.target.value)}
                onBlur={(e) => setModel(e.target.value)}
              >
                {!isLoading ? (
                  models.map((item, index) => (
                    <option value={item.idModele} key={index}>
                      {item.Nom}
                    </option>
                  ))
                ) : (
                  <option value="0">Chargement en cours...</option>
                )}
              </select>
            </div>
          </>
        ) : null}
      </div>
      {showCarInfo ? (
        <CarDetailBlock values={values} img={Car} color={color} />
      ) : null}
    </Container>
  );
};

export default ThirdStep;

const Container = styled.div`
  .mtr-input-wrp {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    border: 2px solid rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    margin-top: 0.5em;
    padding: 4px 0;
    max-width: 470px;
    input {
      margin: 0 !important;
    }
  }

  .info-wrp-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .row {
    display: grid;
    grid-template-columns: 50% 50%;
    h6 {
      font-weight: 400;
      font-size: 17px;
      color: #0a0c50;
      text-transform: unset !important;
      margin: 0.15em 0;
    }
  }

  .country {
    position: absolute;
    right: 0.5em;
  }
  .mtr-input {
    padding: 6px 10px;
    font-size: 14px;
    margin-bottom: 0.35em;
  }
  .mtr-1 {
    text-align: right;
  }
  .mtr-2 {
    border: none;
    border-radius: 0px;
    border-right: 2px solid rgba(0, 0, 0, 0.05);
    border-left: 2px solid rgba(0, 0, 0, 0.05);
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0px;
    text-overflow: "";
    color: #222;
    font-weight: 500;
    margin-bottom: 0 !important;
  }
  .mtr-label-wrp {
    margin-top: 1.1em;
    label {
      margin-top: 0.55em !important;
    }
  }
  .date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4em;
    margin-bottom: 2em;
    flex-wrap: wrap;
    select {
      max-width: 170px;
      min-width: unset;
    }
    h2 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .mtr-input-wrp {
    input:focus::placeholder {
      color: transparent;
    }
  }
`;
